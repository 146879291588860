import React from "react";
import "./SideMenu.scss";
import { ReactComponent as icon1 } from "@frontend/assets/side-menu/icon1.svg";
import { ReactComponent as icon2 } from "@frontend/assets/side-menu/icon2.svg";
import { ReactComponent as notice } from "@frontend/assets/side-menu/notice.svg";
import { ReactComponent as permission } from "@frontend/assets/side-menu/permission.svg";
import { ReactComponent as icon5 } from "@frontend/assets/side-menu/icon5.svg";
import { ReactComponent as line } from "@frontend/assets/side-menu/line.svg";
import Icon from "@ant-design/icons";
import { NavLink, useLocation } from "react-router-dom";

const SideMenu = ({ props }: any) => {
  const location = useLocation();

  const items = [
    {
      icon: icon1,
      label: "會員管理",
      route: "/member/list",
      other: "/",
    },
    {
      icon: icon2,
      label: "類型管理",
      route: "/class/list",
    },
    {
      icon: notice,
      label: "通知推播",
      route: "/notice/list",
    },
    {
      icon: permission,
      label: "權限管理",
      route: "/permission/list",
    },
    {
      icon: icon5,
      label: "話題管理",
      route: "/topic/list",
    },
  ];

  return (
    <div className="side-menu-page">
      {items.map((item, index) => (
        <NavLink
          key={"side-menu-item" + index}
          className={(navData) =>
            (navData.isActive ? "active" : "") +
            " side-menu-item" +
            `${item.other && location.pathname === item.other ? " active" : ""}`
          }
          to={item.route}
        >
          <Icon className="side-menu-item-icon" component={item.icon} />
          <span>{item.label}</span>
          <Icon className="line" component={line} />
        </NavLink>
      ))}
    </div>
  );
};

export default SideMenu;
