import React, { useState } from "react";
import "./Header.scss";
import Icon from "@ant-design/icons";
import { ReactComponent as Back } from "@frontend/assets/shared/back.svg";
import { useAppDispatch } from "@frontend/app/hooks";
import {
  CommonDataState,
  setGoBack,
  signout,
} from "@frontend/common/redux/commonSlice";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { HiOutlineMenu } from "react-icons/hi";
import { MdLogout } from "react-icons/md";

const Header = ({ props }: any) => {
  const dispatch = useAppDispatch();
  const commonDataState = useSelector(CommonDataState);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const location = useLocation();

  const items = [
    {
      label: "會員管理",
      route: "/member/list",
      other: "/",
    },
    {
      label: "類型管理",
      route: "/class/list",
    },
    {
      label: "通知推播",
      route: "/notice/list",
    },
    {
      label: "權限管理",
      route: "/permission/list",
    },
    {
      label: "話題管理",
      route: "/topic/list",
    },
  ];

  return (
    <div
      className="header-page"
      style={{
        justifyContent: commonDataState.goBack ? "space-between" : "flex-end",
      }}
    >
      <div style={{ display: "flex" }}>
        <Icon
          className="menu-icon"
          component={HiOutlineMenu}
          onClick={() => {
            setShowMobileMenu(!showMobileMenu);
          }}
        />

        <Link
          to={commonDataState.goBack}
          className="back"
          style={{ display: commonDataState.goBack ? "flex" : "none" }}
        >
          <Icon className="back-icon" component={Back} />
          <span>返回</span>
        </Link>
      </div>

      <Icon
        className="logout"
        component={MdLogout}
        onClick={() => {
          dispatch(signout());
        }}
      />

      <div
        className={`menu-mobile ${showMobileMenu ? "show-mobile-menu" : ""}`}
      >
        <div className={`menu-mobile-list`}>
          {items.map((item, index) => (
            <NavLink
              key={"side-menu-item" + index}
              className={(navData) =>
                (navData.isActive ? "active" : "") +
                " side-menu-item" +
                `${
                  item.other && location.pathname === item.other
                    ? " active"
                    : ""
                }`
              }
              to={item.route}
              onClick={() => {
                setShowMobileMenu(false);
              }}
            >
              <span>{item.label}</span>
            </NavLink>
          ))}
        </div>

        <div
          className="menu-mobile-mask"
          onClick={() => {
            setShowMobileMenu(!showMobileMenu);
          }}
        ></div>
      </div>
    </div>
  );
};

export default Header;
