export interface FormItem {
  label?: string;
  name: string;
  required: boolean;
  type: string;
  disabled: boolean;
  message?: string;
  value?: string | number;
  hidden?: boolean;
}

export interface NotifiMessage {
  message: string;
  description: any;
  placement: "bottomRight" | undefined;
}

export const NotifiError = (
  message: string,
  code?: number,
  extra?: any
): NotifiMessage => {
  return {
    message: `Error ${code}`,
    description: `${message} ${extra ? extra : ""}`,
    placement: "bottomRight",
  };
};

export const NotifiInfo = (message: string): NotifiMessage => {
  return {
    message: "Info",
    description: message,
    placement: "bottomRight",
  };
};

export const NotifiSuccess = (message: string): NotifiMessage => {
  return {
    message: "Success",
    description: message,
    placement: "bottomRight",
  };
};

export const NotifiWarning = (message: string): NotifiMessage => {
  return {
    message: "Warning",
    description: message,
    placement: "bottomRight",
  };
};

export enum AdminPermisson {
  USER_MANAGEMENT = "USER_MANAGEMENT",
  CATEGORY_MANAGEMENT = "CATEGORY_MANAGEMENT",
  NOTIFICATION_MANAGEMENT = "NOTIFICATION_MANAGEMENT",
  ADMIN_MANAGEMENT = "ADMIN_MANAGEMENT",
  TOPIC_MANAGEMENT = "TOPIC_MANAGEMENT",
}
