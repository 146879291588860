import { useLazyQuery } from "@apollo/client";
import { useAppDispatch } from "@frontend/app/hooks";
import Buttons from "@frontend/common/components/Buttons/Buttons";
import ListComponent from "@frontend/common/components/ListComponent/ListComponent";
import { CreateAdminRequest } from "@frontend/common/models/request.model";
import { Admin, User } from "@frontend/common/models/response.model";
import {
  CommonDataState,
  createAdmin,
  getAdminsPage,
  updateAdmin,
} from "@frontend/common/redux/commonSlice";
import CreateAdmin from "@frontend/pages/permission/CreateAdmin/CreateAdmin";
import PermissionEdit from "@frontend/pages/permission/PermissionEdit/PermissionEdit";
import { Button, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./PermissionList.scss";

const PermissionList = ({ props }: any) => {
  const dispatch = useAppDispatch();
  const commonDataState = useSelector(CommonDataState);
  const [page, setPage] = useState(1);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateAdminModal, setShowCreateAdminModal] = useState(false);
  const [showCreatePermissionModal, setShowCreatePermissionModal] =
    useState(false);
  const [adminDetail, setAdminDetail] = useState<Admin>();
  const [createAdminRequest, setCreateAdminRequest] =
    useState<CreateAdminRequest>();
  const [keyword, setKeyword] = useState("");
  const [adminRequest, setAdminRequest] = useState({ keyword: "" });

  const columns = [
    {
      title: "使用者名稱",
      dataIndex: "name",
      render: (data: any, row: { node: Admin }) => (
        <div className="user-name">
          {/* <div className="user-avatar">
            <img src={row.node.avatarUrl} />
          </div> */}
          <span>{row.node.username}</span>
        </div>
      ),
    },
    {
      title: "帳號",
      dataIndex: "username",
      render: (data: any, row: { node: Admin }) => (
        <div>{row.node.username}</div>
      ),
    },
    {
      title: "個別權限",
      dataIndex: "id",
      render: (id: number, row: { node: Admin }) => (
        <div
          key={"operate" + row.node.id}
          className="operate"
          onClick={() => {
            setShowEditModal(true);
            setAdminDetail(row.node);
          }}
        >
          編輯
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }

    dispatch(
      getAdminsPage({
        skip: (page - 1) * 10,
        limit: 10,
        keyword: adminRequest.keyword,
      })
    );
  }, [page, adminRequest.keyword]);

  return (
    <div className="permission-list-page">
      <div className="container">
        <div className="title">權限管理</div>
        <div className="search">
          <div>
            <Input
              placeholder="搜尋帳號"
              onChange={(v) => {
                setKeyword(v.target.value);
              }}
            />
            <Button
              type="primary"
              className="submit"
              onClick={() => {
                setAdminRequest({ keyword });
                setPage(0);
              }}
            >
              確認
            </Button>
          </div>

          <Button
            type="primary"
            className="create-admin"
            onClick={() => {
              setShowCreateAdminModal(true);
            }}
          >
            新增用戶
          </Button>
        </div>

        <ListComponent
          columns={columns}
          dataSource={commonDataState.admins?.edges}
          total_num={commonDataState.admins?.pageInfo.totalCount}
          page={page}
          output={(v: number) => {
            setPage(v);
          }}
        />
      </div>

      <Modal
        title="權限設定"
        className="permission-edit-modal"
        visible={showEditModal}
        onOk={() => {
          setShowEditModal(false);
        }}
        onCancel={() => {
          setShowEditModal(false);
        }}
        centered
        destroyOnClose
      >
        {adminDetail && (
          <PermissionEdit
            adminPermission={adminDetail.permissions}
            output={(v: string[]) => {
              setAdminDetail({
                ...adminDetail,
                permissions: v,
              });
            }}
          />
        )}
        {adminDetail && (
          <Buttons
            onOk={() => {
              updateAdmin({
                id: adminDetail.id,
                permissions: adminDetail.permissions,
              }).then((v) => {
                dispatch(
                  getAdminsPage({
                    skip: (page - 1) * 10,
                    limit: 10,
                  })
                );
                setAdminDetail(undefined);
                setShowEditModal(false);
              });
            }}
            onCancel={() => {
              setShowEditModal(false);
            }}
          />
        )}
      </Modal>

      <Modal
        title="新增用戶"
        className="create-admin-modal"
        visible={showCreateAdminModal}
        onCancel={() => {
          setShowCreateAdminModal(false);
          setCreateAdminRequest(undefined);
        }}
        centered
        destroyOnClose
      >
        <CreateAdmin
          onOk={(v: any) => {
            setCreateAdminRequest({
              ...createAdminRequest,
              ...v,
              permissions: [],
            });
            setShowCreatePermissionModal(true);
          }}
          onCancel={() => {
            setShowCreateAdminModal(false);
            setCreateAdminRequest(undefined);
          }}
        />
      </Modal>

      <Modal
        title="權限設定"
        className="create-permission-modal"
        visible={showCreatePermissionModal}
        onCancel={() => {
          setShowCreatePermissionModal(false);
        }}
        centered
        destroyOnClose
      >
        {createAdminRequest && (
          <span className="username-title">
            帳號: {createAdminRequest.username}
          </span>
        )}

        {createAdminRequest && (
          <PermissionEdit
            adminPermission={[]}
            output={(v: string[]) => {
              v &&
                setCreateAdminRequest({
                  ...createAdminRequest,
                  permissions: v,
                });
            }}
          />
        )}

        {createAdminRequest && (
          <Buttons
            onOk={() => {
              createAdmin(createAdminRequest).then((v) => {
                if (!v) return;

                dispatch(
                  getAdminsPage({
                    skip: (page - 1) * 10,
                    limit: 10,
                  })
                );
                setShowCreateAdminModal(false);
                setShowCreatePermissionModal(false);
                setCreateAdminRequest(undefined);
              });
            }}
            onCancel={() => {
              setShowCreatePermissionModal(false);
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default PermissionList;
