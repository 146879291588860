import { useLazyQuery } from "@apollo/client";
import { useAppDispatch } from "@frontend/app/hooks";
import ListComponent from "@frontend/common/components/ListComponent/ListComponent";
import { Topics } from "@frontend/common/graphql/query";
import { Category, Topic } from "@frontend/common/models/response.model";
import { CommonDataState, getTopics } from "@frontend/common/redux/commonSlice";
import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./TopicList.scss";

const TopicList = ({ props }: any) => {
  const dispatch = useAppDispatch();
  const commonDataState = useSelector(CommonDataState);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(
      getTopics({
        skip: (page - 1) * 10,
        limit: 10,
      })
    );
  }, [page]);

  const columns = [
    {
      title: "話題名稱",
      dataIndex: "title",
      render: (data: any, row: { node: Topic }) => <div>{row.node.title}</div>,
    },
    {
      title: "編號",
      dataIndex: "id",
      render: (data: any, row: { node: Topic }) => <div>{row.node.id}</div>,
    },
    {
      title: "留言",
      dataIndex: "commentCount",
      render: (data: any, row: { node: Topic }) => (
        <div>{row.node.comments.pageInfo.totalCount}</div>
      ),
    },
    {
      title: "類型",
      dataIndex: "category",
      render: (data: any, row: { node: Topic }) => (
        <div>{row.node.category.name}</div>
      ),
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (data: any, row: { node: Topic }) => (
        <Link to={`${row.node.id}`}>編輯</Link>
      ),
    },
  ];

  return (
    <div className="topic-list-page">
      <div className="container">
        <div className="title">話題管理</div>

        <ListComponent
          columns={columns}
          dataSource={commonDataState.topics?.edges}
          total_num={commonDataState.topics?.pageInfo.totalCount}
          page={page}
          output={(v: number) => {
            setPage(v);
          }}
        />
      </div>
    </div>
  );
};

export default TopicList;
