import { Table } from "antd";
import React from "react";
import "./TableComponent.scss";
import Icon from "@ant-design/icons";
import { ReactComponent as Empty } from "@frontend/assets/shared/empty.svg";

const TableComponent = ({
  columns,
  dataSource,
  objectName,
  rowKey = "id",
  className,
}: {
  columns?: any;
  dataSource?: any;
  objectName?: string;
  rowKey?: string;
  className?: string;
}) => {
  return (
    <Table
      className={`table-component-page ${className}`}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      // rowKey={rowKey}
      rowKey={(obj) => obj[`${objectName}`][`${rowKey}`]}
      locale={{
        emptyText: (
          <div className="table-empty">
            {" "}
            <Icon className="empty-icon" component={Empty} />
            <span>目前沒有資料</span>
          </div>
        ),
      }}
    />
  );
};

export default TableComponent;
