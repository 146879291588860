import React, { useEffect, useState } from "react";
import "./Login.scss";
import Logo from "@frontend/assets/login/logo.png";
import { Button, Form, Input } from "antd";
import { useAppDispatch } from "@frontend/app/hooks";
import { adminSignIn } from "@frontend/common/redux/commonSlice";

const Login = ({ props }: any) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loginError, setLoginError] = useState<boolean>(false);

  useEffect(() => {
    if (!loginError) return;

    form.setFieldsValue({ username: "" });
    form.setFieldsValue({ password: "" });
    form.validateFields();
  }, [loginError]);

  return (
    <div className="login-page">
      <div className="logo">
        <img src={Logo} />
      </div>

      <div className="login">
        <span className="title">MyBoom管理系統</span>

        <Form
          className="form-page"
          form={form}
          layout="vertical"
          onFinish={() => {
            form.validateFields().then((v) => {});
          }}
        >
          <Form.Item
            label="帳號"
            name="username"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value && loginError) {
                    return Promise.reject(new Error("帳號輸入錯誤"));
                  }

                  setLoginError(false);
                  return Promise.resolve();
                },
              }),
            ]}
            initialValue=""
          >
            <Input type="text" placeholder="請輸入ID" />
          </Form.Item>

          <Form.Item
            label="密碼"
            name="password"
            hasFeedback
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value && loginError) {
                    return Promise.reject(new Error("密碼輸入錯誤"));
                  }

                  setLoginError(false);
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password placeholder="請輸入密碼" />
          </Form.Item>
        </Form>

        <Button
          className="submit"
          type="primary"
          onClick={() => {
            form.validateFields().then((v) => {
              dispatch(adminSignIn(v)).then((x) => {
                !x.payload && setLoginError(true);
              });
            });
          }}
        >
          確定登入
        </Button>
      </div>
    </div>
  );
};

export default Login;
