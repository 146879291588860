import {
  ApolloClient,
  ApolloLink,
  concat,
  InMemoryCache,
} from "@apollo/client";
import {
  NotifiError,
  NotifiSuccess,
} from "@frontend/common/models/common.model";
import { message, notification } from "antd";
import { createUploadLink } from "apollo-upload-client";

const messageConfig = {
  content: " ",
  duration: 0,
};
var loadingLock = false;

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  if (localStorage.getItem("token")) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }

  return forward(operation);
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
    query: {
      fetchPolicy: "network-only",
    },
  },
  link: concat(
    authMiddleware,
    createUploadLink({
      uri: "https://api.myboom.tw/graphql",
    })
  ),
});

export enum Method {
  query = "query",
  mutation = "mutation",
}

export const gqlClient = (method: Method, gql: any, variables?: any) => {
  switch (method) {
    case Method.query:
      if (!loadingLock) {
        message.loading(messageConfig);
        loadingLock = true;
      }

      return client
        .query({ query: gql, variables })
        .then((v) => {
          message.destroy();
          loadingLock = false;
          return v.data;
        })
        .catch((e) => {
          message.destroy();
          if (
            e.networkError.result.errors &&
            e.networkError.result.errors.length > 0 &&
            e.networkError.result.errors[0]?.extensions?.code ===
              "ERROR_INVALID_TOKEN"
          ) {
            localStorage.removeItem("token");
          }
          notification.error(NotifiError(e.message));
          return null;
        });

    case Method.mutation:
      return client
        .mutate({ mutation: gql, variables })
        .then((v) => {
          notification.success(NotifiSuccess(""));
          return v.data;
        })
        .catch((e) => {
          if (
            e.networkError.result.errors &&
            e.networkError.result.errors.length > 0 &&
            e.networkError.result.errors[0]?.extensions?.code ===
              "ERROR_INVALID_TOKEN"
          ) {
            localStorage.removeItem("token");
          }
          notification.error(NotifiError(e.message, 404));
          return null;
        });

    default:
      console.log(`未知的 method: ${method}`);
      return false;
  }
};
