import PaginationComponent from "@frontend/common/components/PaginationComponent/PaginationComponent";
import TableComponent from "@frontend/common/components/TableComponent/TableComponent";
import React from "react";
import "./ListComponent.scss";

const ListComponent = ({
  columns,
  dataSource,
  total_num,
  page,
  objectName = "node",
  rowKey = "id",
  className,
  output = () => {},
}: {
  columns: any;
  dataSource?: any;
  total_num?: number;
  page?: number;
  objectName?: string;
  rowKey?: string;
  className?: string;
  output?: Function;
}) => {
  return (
    <div className="list-component-page">
      <TableComponent
        className="table-page"
        columns={columns}
        dataSource={dataSource}
        objectName={objectName}
        rowKey={rowKey}
      />

      <PaginationComponent
        props={{ total_num: total_num, page }}
        output={(page: number) => {
          output(page);
        }}
      />
    </div>
  );
};

export default ListComponent;
