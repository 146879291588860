import { Pagination } from "antd";
import React from "react";
import "./PaginationComponent.scss";
import Icon from "@ant-design/icons";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

interface PropsType {
  total_num?: number;
  page?: number;
  average_num?: number;
}

const PaginationComponent = ({
  props,
  output = () => {},
}: {
  props: PropsType;
  output?: Function;
}) => {
  return (
    <div className="pagination-component-page">
      {props.total_num ? (
        <Pagination
          current={props.page}
          defaultCurrent={props.total_num / 10}
          total={props.total_num}
          pageSize={props.average_num || 10}
          onChange={(v) => {
            output(v);
          }}
          itemRender={(current, type, e) => {
            if (type === "prev") {
              return (
                <button className="ant-pagination-item-link pre-btn">
                  <Icon
                    className="action action-left"
                    component={AiOutlineArrowLeft}
                  />
                </button>
              );
            }

            if (type === "next") {
              return (
                <button className="ant-pagination-item-link next-btn">
                  <Icon
                    className="action action-right"
                    component={AiOutlineArrowRight}
                  />
                </button>
              );
            }

            return e;
          }}
        />
      ) : null}
    </div>
  );
};

export default PaginationComponent;
