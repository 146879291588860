import { gql } from "@apollo/client";

export const Users = gql`
  query Users($skip: Int, $limit: Int!, $followedBy: String, $keyword: String) {
    users(
      skip: $skip
      limit: $limit
      followedBy: $followedBy
      keyword: $keyword
    ) {
      edges {
        node {
          id
          name
          avatarUrl
          email
          registeredAt
          topicCount
          commentedCount
          commentCount
          savedCount
          votedCount
          followerCount
          followingCount
          appleIdentifier
          googleIdentifier
          facebookIdentifier
          lineIdentifier
          isFollowing
          watchingTopicIDs
        }
      }
      pageInfo {
        totalCount
        hasNextPage
      }
    }
  }
`;

export const User = gql`
  query User($id: String!) {
    user(id: $id) {
      id
      name
      avatarUrl
      email
      registeredAt
      topicCount
      commentedCount
      commentCount
      savedCount
      votedCount
      followerCount
      followingCount
      appleIdentifier
      googleIdentifier
      facebookIdentifier
      lineIdentifier
      isFollowing
      watchingTopicIDs
    }
  }
`;

export const UserTopics = gql`
  query Topics(
    $skip: Int
    $limit: Int
    $authorId: String
    $isVoted: Boolean
    $isSaved: Boolean
    $keyword: String
    $categoryId: String
  ) {
    topics(
      skip: $skip
      limit: $limit
      authorId: $authorId
      isVoted: $isVoted
      isSaved: $isSaved
      keyword: $keyword
      categoryId: $categoryId
    ) {
      edges {
        node {
          id
          title
        }
      }
      pageInfo {
        totalCount
        hasNextPage
      }
    }
  }
`;

export const Categories = gql`
  query categories($skip: Int, $limit: Int) {
    categories(skip: $skip, limit: $limit) {
      edges {
        node {
          id
          name
          imageUrl
          topicCount
          starCount
        }
      }
      pageInfo {
        totalCount
        hasNextPage
      }
    }
  }
`;

export const NotificationTasks = gql`
  query NotificationTasks($skip: Int, $limit: Int, $isSent: Boolean) {
    notificationTasks(skip: $skip, limit: $limit, isSent: $isSent) {
      edges {
        node {
          id
          title
          content
          createdAt
          createdBy
          time
          cancelAt
        }
      }
      pageInfo {
        totalCount
        hasNextPage
      }
    }
  }
`;

export const Topics = gql`
  query Topics(
    $skip: Int
    $limit: Int
    $authorId: String
    $isVoted: Boolean
    $isSaved: Boolean
    $keyword: String
    $categoryId: String
  ) {
    topics(
      skip: $skip
      limit: $limit
      authorId: $authorId
      isVoted: $isVoted
      isSaved: $isSaved
      keyword: $keyword
      categoryId: $categoryId
    ) {
      edges {
        node {
          id
          title
          category {
            id
            name
            imageUrl
            topicCount
          }
          isVoted
          voteCount
          isSaved
          createdAt
          options {
            id
            name
          }
          comments {
            edges {
              node {
                id
              }
            }
            pageInfo {
              totalCount
              hasNextPage
            }
          }
        }
      }
      pageInfo {
        totalCount
        hasNextPage
      }
    }
  }
`;

export const Topic = gql`
  query Topic($id: String!) {
    topic(id: $id) {
      id
      title
      isVoted
      voteCount
      isSaved
      createdAt
      options {
        id
        name
      }
      category {
        id
        name
        imageUrl
        topicCount
        starCount
      }
      comments {
        edges {
          node {
            id
            createdAt
            content
            author {
              id
              name
            }
          }
        }
        pageInfo {
          totalCount
          hasNextPage
        }
      }
    }
  }
`;

export const Admins = gql`
  query Admins($skip: Int, $limit: Int!, $keyword: String) {
    admins(skip: $skip, limit: $limit, keyword: $keyword) {
      edges {
        node {
          id
          username
          nickname
          createdAt
          permissions
        }
      }
      pageInfo {
        totalCount
        hasNextPage
      }
    }
  }
`;

export const Comments = gql`
  query Comments($skip: Int, $limit: Int, $topicId: String, $authorId: String) {
    comments(
      skip: $skip
      limit: $limit
      topicId: $topicId
      authorId: $authorId
    ) {
      edges {
        node {
          id
          createdAt
          content
          author {
            id
            name
          }
        }
      }
      pageInfo {
        totalCount
        hasNextPage
      }
    }
  }
`;
