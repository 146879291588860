import Buttons from "@frontend/common/components/Buttons/Buttons";
import { Form, Input } from "antd";
import React from "react";
import "./CreateAdmin.scss";

const CreateAdmin = ({
  onOk = () => {},
  onCancel = () => {},
}: {
  onOk: Function;
  onCancel: Function;
}) => {
  const [form] = Form.useForm();

  return (
    <div className="create-admin-page">
      <Form
        className="form-page"
        form={form}
        layout="vertical"
        onFinish={() => {
          form.validateFields().then((v) => {});
        }}
      >
        <Form.Item
          label="帳號"
          name="username"
          rules={[{ required: true, message: "帳號不可為空" }]}
          initialValue=""
        >
          <Input type="text" placeholder="請輸入ID" />
        </Form.Item>

        <Form.Item
          label="密碼"
          name="password"
          hasFeedback
          rules={[{ required: true, message: "密碼不可為空" }]}
        >
          <Input.Password placeholder="請輸入密碼" />
        </Form.Item>

        <Form.Item
          label="確認密碼"
          name="password2"
          hasFeedback
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue("password") !== value) {
                  return Promise.reject(new Error("兩次輸入密碼不一致"));
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password placeholder="請輸入密碼" />
        </Form.Item>
      </Form>

      <Buttons
        onOk={() => {
          form.validateFields().then((v) => {
            onOk({
              username: v.username,
              nickname: v.username,
              password: v.password,
            });
          });
        }}
        onCancel={() => {
          onCancel(false);
        }}
      />
    </div>
  );
};

export default CreateAdmin;
