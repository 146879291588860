import { Button, Form, Input, Upload } from "antd";
import React, { useEffect, useState } from "react";
import "./MemberEdit.scss";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch } from "@frontend/app/hooks";
import { useSelector } from "react-redux";
import {
  clearUser,
  CommonDataState,
  getUser,
  getUserTopics,
  setGoBack,
  updateUserProfile,
} from "@frontend/common/redux/commonSlice";
import { Topic, UserTopicPage } from "@frontend/common/models/response.model";
import { ReactComponent as Edit } from "@frontend/assets/shared/edit.svg";
import Icon from "@ant-design/icons";
import PaginationComponent from "@frontend/common/components/PaginationComponent/PaginationComponent";
import { getBase64 } from "@frontend/common/services/common.service";

const MemberEdit = ({ props }: any) => {
  const dispatch = useAppDispatch();
  const commonDataState = useSelector(CommonDataState);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [imageFile, setImageFile] = useState<any>();
  const [page, setPage] = useState(1);
  const [previewImage, setPreviewImage] = useState<any>("");

  const beforeUpload = async (file: any) => {
    setImageFile(file);

    const aa = await getBase64(file);
    setPreviewImage(aa);
  };

  useEffect(() => {
    id && dispatch(getUser({ id }));
  }, [id]);

  useEffect(() => {
    if (!commonDataState.user) return;

    dispatch(
      getUserTopics({
        skip: (page - 1) * 14,
        limit: 14,
        authorId: commonDataState.user.id,
      })
    );
  }, [commonDataState.user, page]);

  useEffect(() => {
    dispatch(setGoBack("/member/list"));

    return () => {
      dispatch(clearUser());
      dispatch(setGoBack(""));
    };
  }, [dispatch]);

  return (
    commonDataState.user && (
      <div className="member-edit-page">
        <div className="title">會員管理</div>

        <div className="content">
          <div className="member-detail">
            <div style={{ position: "relative" }}>
              <div className="member-detail-avatar">
                {previewImage ? (
                  <img src={previewImage} />
                ) : (
                  <img src={commonDataState.user.avatarUrl} />
                )}
              </div>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                <Icon className="upload-edit" component={Edit} />
              </Upload>
            </div>

            <Form
              className="form-page"
              form={form}
              layout="vertical"
              onFinish={() => {
                form.validateFields().then((v) => {});
              }}
            >
              <Form.Item
                label="姓名"
                name="name"
                rules={[{ required: true, message: "不可空白" }]}
                initialValue={commonDataState.user.name}
              >
                <Input type="text" />
              </Form.Item>

              <Form.Item
                label="電子郵件"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "不可空白",
                  },
                ]}
                initialValue={commonDataState.user.email}
              >
                <Input type="text" />
              </Form.Item>
            </Form>

            <Button
              type="primary"
              className="submit"
              onClick={() => {
                form.validateFields().then((v) => {
                  updateUserProfile({
                    ...v,
                    id: commonDataState.user?.id,
                    image: imageFile,
                  })
                    .then((x) => {
                      console.log("successs");
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                });
              }}
            >
              確認
            </Button>
          </div>
          <div className="topic-management">
            <div className="topic-management-title">發佈話題管理</div>
            <div className="topic-management-content">
              {commonDataState.userTopics?.edges.map((v: { node: Topic }) => (
                <div key={"user-topic" + v.node.id} className="user-topic">
                  <span className="user-topic-title">{v.node.title}</span>
                  <Link to={`/topic/list/${v.node.id}`}>前往話題管理</Link>
                </div>
              ))}
            </div>

            <div className="topic-management-pagination">
              <PaginationComponent
                props={{
                  page,
                  total_num: commonDataState.userTopics?.pageInfo.totalCount,
                  average_num: 14,
                }}
                output={(v: number) => {
                  setPage(v);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default MemberEdit;
