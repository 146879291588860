import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "antd/dist/antd.less";
import "./default.style.scss";
import "./default.theme.scss";
import { ApolloProvider } from "@apollo/client";
import { client } from "@frontend/common/graphql/client";
import { Provider } from "react-redux";
import { store } from "@frontend/app/store";

ReactDOM.render(
  // <ApolloProvider client={client}>
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  // </ApolloProvider>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
