import React, { useCallback, useEffect, useState } from "react";
import "./ClassList.scss";
import ListComponent from "@frontend/common/components/ListComponent/ListComponent";
import { Button, Input, Modal, Upload } from "antd";
import { ReactComponent as CirclePlus } from "@frontend/assets/shared/circle-plus.svg";
import Icon from "@ant-design/icons";
import Buttons from "@frontend/common/components/Buttons/Buttons";
import { useAppDispatch } from "@frontend/app/hooks";
import {
  CommonDataState,
  createCategory,
  deleteCategory,
  getCategories,
  updateCategory,
} from "@frontend/common/redux/commonSlice";
import { useSelector } from "react-redux";
import { Category } from "@frontend/common/models/response.model";
import {
  CreateCategoryRequest,
  DeleteCategoryRequest,
  UpdateCategoryRequest,
} from "@frontend/common/models/request.model";
import { getBase64 } from "@frontend/common/services/common.service";

const ClassList = ({ props }: any) => {
  const dispatch = useAppDispatch();
  const commonDataState = useSelector(CommonDataState);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [previewImage, setPreviewImage] = useState<any>("");
  const initCreateCategoryRequest = {
    name: "",
    image: "",
  };
  const [createCategoryRequest, setCreateCategoryRequest] =
    useState<CreateCategoryRequest>(initCreateCategoryRequest);
  const initUpdateCategoryRequest = {
    id: "",
  };
  const [updateCategoryRequest, setUpdateCategoryRequest] =
    useState<UpdateCategoryRequest>(initUpdateCategoryRequest);
  const [deleteCategoryRequest, setDeleteCategoryRequest] =
    useState<DeleteCategoryRequest>({ id: "" });
  const columns = [
    {
      title: "類型名稱",
      dataIndex: "name",
      render: (data: any, row: { node: Category }) => (
        <div className="class-name">
          <div className="class-avatar">
            <img src={row.node.imageUrl} />
          </div>
          <span>{row.node.name}</span>
        </div>
      ),
    },
    {
      title: "文章數量",
      dataIndex: "topicCount",
      render: (data: any, row: { node: Category }) => (
        <div>{row.node.topicCount}</div>
      ),
    },
    {
      title: "總星星數",
      dataIndex: "starCount",
      render: (data: any, row: { node: Category }) => (
        <div>{row.node.starCount}</div>
      ),
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (data: any, row: { node: Category }) => (
        <div className="class-operate">
          <div
            className="class-delete"
            onClick={() => {
              setDeleteCategoryRequest({ id: row.node.id });
              setShowDeleteModal(true);
            }}
          >
            刪除
          </div>
          <Button
            type="link"
            className="class-update"
            onClick={() => {
              setUpdateCategoryRequest({
                id: row.node.id,
                newName: row.node.name,
              });
              setShowUpdateModal(true);
              setPreviewImage(row.node.imageUrl);
            }}
          >
            編輯
          </Button>
        </div>
      ),
    },
  ];

  const createModalCancel = () => {
    setShowCreateModal(false);
    setCreateCategoryRequest(initCreateCategoryRequest);
    setPreviewImage("");
  };

  const updateModalCancel = () => {
    setShowUpdateModal(false);
    setUpdateCategoryRequest(initUpdateCategoryRequest);
    setPreviewImage("");
  };

  const beforeUpload = async (file: any) => {
    setCreateCategoryRequest({ ...createCategoryRequest, image: file });

    const aa = await getBase64(file);
    setPreviewImage(aa);
  };

  const updateBeforeUpload = async (file: any) => {
    setUpdateCategoryRequest({ ...updateCategoryRequest, newImage: file });

    const aa = await getBase64(file);
    setPreviewImage(aa);
  };

  const getCategoriesPage = useCallback(() => {
    dispatch(getCategories({ skip: 0, limit: 100 })).catch((e) => {});
  }, [dispatch]);

  useEffect(() => {
    getCategoriesPage();
  }, [getCategoriesPage]);

  return (
    <div className="class-list-page">
      <div className="container">
        <div className="title">類型管理</div>

        <div className="create">
          <Button
            type="primary"
            className="submit"
            onClick={() => {
              setShowCreateModal(true);
            }}
          >
            新增類型
          </Button>
        </div>

        <ListComponent
          columns={columns}
          dataSource={commonDataState.categories?.edges}
        />
      </div>

      <Modal
        title="新增類型"
        className="class-list-create-modal"
        visible={showCreateModal}
        onOk={() => {
          createModalCancel();
        }}
        onCancel={() => {
          createModalCancel();
        }}
        centered
        destroyOnClose
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
        >
          {previewImage ? (
            <img style={{ width: "100%" }} src={previewImage} />
          ) : (
            <div className="upload-content">
              <Icon className="circle-plus" component={CirclePlus} />
              <span>請上傳圖片</span>
              <span className="description">建議解析比例6:5</span>
            </div>
          )}
        </Upload>

        <Input
          placeholder="請輸入類型名稱"
          onChange={(v) => {
            setCreateCategoryRequest({
              ...createCategoryRequest,
              name: v.target.value,
            });
          }}
        />

        <Buttons
          onOk={() => {
            if (
              createCategoryRequest.name === "" ||
              createCategoryRequest.image === ""
            )
              return;

            createCategory(createCategoryRequest).then((v) => {
              if (v) {
                createModalCancel();
                getCategoriesPage();
              }
            });
          }}
          onCancel={() => {
            createModalCancel();
          }}
        />
      </Modal>

      <Modal
        title="編輯類型"
        className="class-list-update-modal"
        visible={showUpdateModal}
        onOk={() => {
          updateModalCancel();
        }}
        onCancel={() => {
          updateModalCancel();
        }}
        centered
        destroyOnClose
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={updateBeforeUpload}
        >
          {previewImage ? (
            <img style={{ width: "100%" }} src={previewImage} />
          ) : (
            <div className="upload-content">
              <Icon className="circle-plus" component={CirclePlus} />
              <span>請上傳圖片</span>
              <span className="description">建議解析比例6:5</span>
            </div>
          )}
        </Upload>

        <Input
          placeholder="請輸入類型名稱"
          value={updateCategoryRequest.newName}
          onChange={(v) => {
            setUpdateCategoryRequest({
              ...updateCategoryRequest,
              newName: v.target.value,
            });
          }}
        />

        <Buttons
          onOk={() => {
            if (updateCategoryRequest.id === "") return;

            updateCategory(updateCategoryRequest).then((v) => {
              if (v) {
                updateModalCancel();

                getCategoriesPage();
              }
            });
          }}
          onCancel={() => {
            updateModalCancel();
          }}
        />
      </Modal>

      <Modal
        title="通知"
        className="delete-topic-modal"
        visible={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        centered
        destroyOnClose
      >
        <span className="delete-topic-content">確認要刪除?</span>
        <Buttons
          onOk={() => {
            if (deleteCategoryRequest.id === "") return;

            deleteCategory(deleteCategoryRequest).then((v) => {
              if (v) {
                setShowDeleteModal(false);
                getCategoriesPage();
              }
            });
          }}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default ClassList;
