import { Method } from "@frontend/common/graphql/client";
import { AdminPermisson } from "@frontend/common/models/common.model";
import { Button, Checkbox, Form } from "antd";
import React, { useEffect, useState } from "react";
import "./PermissionEdit.scss";

const PermissionEdit = ({
  adminPermission,
  output = () => {},
}: {
  adminPermission?: string[];
  output: Function;
}) => {
  const permissionList = [
    { name: "會員管理", value: AdminPermisson.USER_MANAGEMENT },
    { name: "類型管理", value: AdminPermisson.CATEGORY_MANAGEMENT },
    { name: "通知推播", value: AdminPermisson.NOTIFICATION_MANAGEMENT },
    { name: "權限管理", value: AdminPermisson.ADMIN_MANAGEMENT },
    { name: "話題管理", value: AdminPermisson.TOPIC_MANAGEMENT },
  ];

  const [permissions, setPermissions] = useState<string[]>();

  const checkboxOnChange = (e: any) => {
    setPermissions(e);
  };

  useEffect(() => {
    permissions && output(permissions);
  }, [permissions]);

  return (
    <Checkbox.Group
      className="permission-edit-page"
      onChange={checkboxOnChange}
      defaultValue={adminPermission}
    >
      {permissionList.map((v) => (
        <Button
          key={v.value}
          className={`permission-item ${
            permissions
              ? permissions.indexOf(v.value) !== -1
                ? "active"
                : ""
              : adminPermission?.indexOf(v.value) !== -1
              ? "active"
              : ""
          }`}
        >
          <Checkbox value={v.value}>{v.name}</Checkbox>
        </Button>
      ))}
    </Checkbox.Group>
  );
};

export default PermissionEdit;
