import { gql } from "@apollo/client";

export const AdminSignIn = gql`
  mutation AdminSignIn($username: String!, $password: String!) {
    adminSignIn(username: $username, password: $password) {
      token
      admin {
        id
        username
        nickname
        permissions
      }
    }
  }
`;

export const UpdateUserProfile = gql`
  mutation UpdateUserProfile(
    $id: ID!
    $email: String
    $name: String
    $image: Upload
  ) {
    updateUserProfile(id: $id, email: $email, name: $name, image: $image) {
      id
    }
  }
`;

export const CreateCategory = gql`
  mutation CreateCategory($name: String!, $image: Upload!) {
    createCategory(name: $name, image: $image) {
      id
      name
      imageUrl
      topicCount
    }
  }
`;

export const UpdateCategory = gql`
  mutation updateCategory($id: String!, $newName: String, $newImage: Upload) {
    updateCategory(id: $id, newName: $newName, newImage: $newImage) {
      id
    }
  }
`;

export const DeleteCategory = gql`
  mutation deleteCategory($id: String!) {
    deleteCategory(id: $id)
  }
`;

export const SendNotification = gql`
  mutation SendNotification(
    $title: String!
    $content: String!
    $sendTime: String!
  ) {
    sendNotification(title: $title, content: $content, sendTime: $sendTime) {
      id
      title
      content
      createdAt
      createdBy
      time
      cancelAt
    }
  }
`;

export const CancelNotificationTask = gql`
  mutation CancelNotificationTask($taskID: ID!) {
    cancelNotificationTask(taskID: $taskID) {
      id
    }
  }
`;

export const UpdateTopic = gql`
  mutation UpdateTopic($id: ID!, $title: String, $categoryId: String) {
    updateTopic(id: $id, title: $title, categoryId: $categoryId) {
      id
    }
  }
`;

export const DeleteTopic = gql`
  mutation DeleteTopic($topicID: String!) {
    deleteTopic(topicID: $topicID)
  }
`;

export const DeleteComment = gql`
  mutation DeleteComment($commentID: ID!) {
    deleteComment(commentID: $commentID)
  }
`;

export const UpdateAdmin = gql`
  mutation UpdateAdmin(
    $id: ID!
    $username: String
    $password: String
    $nickname: String
    $permissions: [AdminPermission!]
  ) {
    updateAdmin(
      id: $id
      username: $username
      password: $password
      nickname: $nickname
      permissions: $permissions
    ) {
      id
    }
  }
`;

export const CreateAdmin = gql`
  mutation CreateAdmin(
    $username: String!
    $password: String!
    $nickname: String!
    $permissions: [AdminPermission!]!
  ) {
    createAdmin(
      username: $username
      password: $password
      nickname: $nickname
      permissions: $permissions
    ) {
      id
    }
  }
`;
