import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@frontend/app/store";
import {
  AdminSignInRequest,
  AdminsRequest,
  CancelNotificationTaskRequest,
  CategoriesRequest,
  CommentsRequest,
  CreateAdminRequest,
  CreateCategoryRequest,
  DeleteCategoryRequest,
  DeleteCommentRequest,
  DeleteTopicRequest,
  NotificationTasksRequest,
  SendNotificationRequest,
  TopicRequest,
  TopicsRequest,
  UpdateAdminRequest,
  UpdateCategoryRequest,
  UpdateTopicRequest,
  UpdateUserProfileRequest,
  UserRequest,
  UsersRequest,
} from "@frontend/common/models/request.model";
import {
  Admin,
  AdminPage,
  Category,
  CategoryPage,
  NotificationTask,
  NotificationTaskPage,
  Topic as TopicProp,
  TopicPage,
  User,
  UserPage,
  UserTopicPage,
} from "@frontend/common/models/response.model";
import {
  AdminSignIn,
  CancelNotificationTask,
  CreateAdmin,
  CreateCategory,
  DeleteCategory,
  DeleteComment,
  DeleteTopic,
  SendNotification,
  UpdateAdmin,
  UpdateCategory,
  UpdateTopic,
  UpdateUserProfile,
} from "@frontend/common/graphql/mutation";
import {
  Admins,
  Categories,
  Comments,
  NotificationTasks,
  Topic,
  Topics,
  User as findUser,
  Users,
} from "@frontend/common/graphql/query";
import { gqlClient, Method } from "@frontend/common/graphql/client";

export interface commonDataState {
  token: string | null;
  admin: Admin | null;
  users: UserPage | null;
  user: User | null;
  userTopics: UserTopicPage | null;
  notificationTasks: NotificationTaskPage | null;
  categories: CategoryPage | null;
  topics: TopicPage | null;
  topic: TopicProp | null;
  goBack: string;
  admins: AdminPage | null;
}

const initialState: commonDataState = {
  token: null,
  admin: null,
  users: null,
  user: null,
  userTopics: null,
  notificationTasks: null,
  categories: null,
  topics: null,
  topic: null,
  goBack: "",
  admins: null,
};

export const adminSignIn = createAsyncThunk(
  "web/login",
  async (adminSignInRequest: AdminSignInRequest) => {
    const data: any = await gqlClient(
      Method.mutation,
      AdminSignIn,
      adminSignInRequest
    );

    return data ? data.adminSignIn : data;
  }
);

export const getUsersPage = createAsyncThunk(
  "web/users",
  async (usersRequest: UsersRequest) => {
    const data: any = await gqlClient(Method.query, Users, usersRequest);

    return data ? data.users : data;
  }
);

export const getUser = createAsyncThunk(
  "web/user",
  async (userRequest: UserRequest) => {
    const data: any = await gqlClient(Method.query, findUser, userRequest);

    return data ? data.user : data;
  }
);

export const getUserTopics = createAsyncThunk(
  "web/userTopic",
  async (topicsRequest: TopicsRequest) => {
    const data: any = await gqlClient(Method.query, Topics, topicsRequest);

    return data ? data.topics : data;
  }
);

export const updateUserProfile = async (
  updateUserProfileRequest: UpdateUserProfileRequest
) => {
  const data: any = await gqlClient(
    Method.mutation,
    UpdateUserProfile,
    updateUserProfileRequest
  );

  return data ? data.updateUserProfile : data;
};

export const getNotificationTasks = createAsyncThunk(
  "web/otificationTasks",
  async (notificationTasksRequest: NotificationTasksRequest) => {
    const data: any = await gqlClient(
      Method.query,
      NotificationTasks,
      notificationTasksRequest
    );
    return data ? data.notificationTasks : data;
  }
);

export const getCategories = createAsyncThunk(
  "web/categories",
  async (categoriesRequest: CategoriesRequest) => {
    const data: any = await gqlClient(
      Method.query,
      Categories,
      categoriesRequest
    );

    return data ? data.categories : data;
  }
);

export const getTopics = createAsyncThunk(
  "web/topics",
  async (topicsRequest: TopicsRequest) => {
    const data: any = await gqlClient(Method.query, Topics, topicsRequest);

    return data ? data.topics : data;
  }
);

export const getTopic = createAsyncThunk(
  "web/topic",
  async (topicRequest: TopicRequest) => {
    const data: any = await gqlClient(Method.query, Topic, topicRequest);

    return data ? data.topic : data;
  }
);

export const updateTopic = async (updateTopicRequest: UpdateTopicRequest) => {
  const data: any = await gqlClient(
    Method.mutation,
    UpdateTopic,
    updateTopicRequest
  );

  return data ? data.updateTopic : data;
};

export const createCategory = async (
  createCategoryRequest: CreateCategoryRequest
) => {
  const data: any = await gqlClient(
    Method.mutation,
    CreateCategory,
    createCategoryRequest
  );

  return data ? data.createCategory : data;
};

export const updateCategory = async (
  updateCategoryRequest: UpdateCategoryRequest
) => {
  const data: any = await gqlClient(
    Method.mutation,
    UpdateCategory,
    updateCategoryRequest
  );

  return data ? data.updateCategory : data;
};

export const deleteCategory = async (
  deleteCategoryRequest: DeleteCategoryRequest
) => {
  const data: any = await gqlClient(
    Method.mutation,
    DeleteCategory,
    deleteCategoryRequest
  );

  return data ? data.deleteCategory : data;
};

export const sendNotification = async (
  sendNotificationRequest: SendNotificationRequest
) => {
  const data: any = await gqlClient(
    Method.mutation,
    SendNotification,
    sendNotificationRequest
  );

  return data ? data.sendNotification : data;
};

export const cancelNotificationTask = async (
  cancelNotificationTaskRequest: CancelNotificationTaskRequest
) => {
  const data: any = await gqlClient(
    Method.mutation,
    CancelNotificationTask,
    cancelNotificationTaskRequest
  );

  return data ? data.cancelNotificationTask : data;
};

export const getAdminsPage = createAsyncThunk(
  "web/admins",
  async (adminsRequest: AdminsRequest) => {
    const data: any = await gqlClient(Method.query, Admins, adminsRequest);

    return data ? data.admins : data;
  }
);

export const deleteComment = async (
  deleteCommentRequest: DeleteCommentRequest
) => {
  const data: any = await gqlClient(
    Method.mutation,
    DeleteComment,
    deleteCommentRequest
  );

  return data ? data.deleteComment : data;
};

export const createAdmin = async (createAdminRequest: CreateAdminRequest) => {
  const data: any = await gqlClient(
    Method.mutation,
    CreateAdmin,
    createAdminRequest
  );

  return data ? data.createAdmin : data;
};

export const deleteTopic = async (deleteTopicRequest: DeleteTopicRequest) => {
  const data: any = await gqlClient(
    Method.mutation,
    DeleteTopic,
    deleteTopicRequest
  );

  return data ? data.deleteTopic : data;
};

export const updateAdmin = async (updateAdminRequest: UpdateAdminRequest) => {
  const data: any = await gqlClient(
    Method.mutation,
    UpdateAdmin,
    updateAdminRequest
  );

  return data ? data.updateAdmin : data;
};

export const getComments = createAsyncThunk(
  "web/comments",
  async (commentsRequest: CommentsRequest) => {
    const data: any = await gqlClient(Method.query, Comments, commentsRequest);

    return data ? data.comments : data;
  }
);

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setGoBack(state, action) {
      state.goBack = action.payload;
    },
    loadToken(state) {
      if (localStorage.getItem("token")) {
        state.token = `${localStorage.getItem("token")}`;
      }
    },
    signout(state) {
      state.token = null;
      localStorage.removeItem("token");
    },
    clearUser(state) {
      state.user = null;
      state.userTopics = null;
    },
    clearTopic(state) {
      state.topic = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminSignIn.fulfilled, (state, action) => {
      if (!action.payload) return;

      state.token = action.payload.token;
      localStorage.setItem("token", action.payload.token);
      state.admin = action.payload.admin;
    });
    builder.addCase(getUsersPage.fulfilled, (state, action) => {
      if (!action.payload) return;

      state.users = action.payload;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      if (!action.payload) return;

      state.user = action.payload;
    });
    builder.addCase(getNotificationTasks.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.notificationTasks = action.payload;
    });
    builder.addCase(getCategories.fulfilled, (state, action) => {
      if (!action.payload) return;

      state.categories = action.payload;
    });
    builder.addCase(getTopics.fulfilled, (state, action) => {
      if (!action.payload) return;

      state.topics = action.payload;
    });
    builder.addCase(getTopic.fulfilled, (state, action) => {
      if (!action.payload) return;

      state.topic = action.payload;
    });
    builder.addCase(getUserTopics.fulfilled, (state, action) => {
      if (!action.payload) return;

      state.userTopics = action.payload;
    });
    builder.addCase(getAdminsPage.fulfilled, (state, action) => {
      if (!action.payload) return;

      state.admins = action.payload;
    });
    builder.addCase(getComments.fulfilled, (state, action) => {
      if (!action.payload) return;

      if (state.topic) {
        state.topic.comments = action.payload;
      }
    });
  },
});

export const { setGoBack, signout, loadToken, clearUser, clearTopic } =
  commonSlice.actions;
export const CommonDataState = (state: RootState) => state.common;

export default commonSlice.reducer;
