import { useAppDispatch } from "@frontend/app/hooks";
import ListComponent from "@frontend/common/components/ListComponent/ListComponent";
import { User } from "@frontend/common/models/response.model";
import {
  CommonDataState,
  getUsersPage,
} from "@frontend/common/redux/commonSlice";
import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./MemberList.scss";

const MemberList = ({ props }: any) => {
  const dispatch = useAppDispatch();
  const commonDataState = useSelector(CommonDataState);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [userRequest, setUserRequest] = useState({ keyword: "" });
  const location = useLocation();
  // const navigate = useNavigate();

  useEffect(() => {
    if (page === 0) {
      setPage(1);
      return;
    }

    dispatch(
      getUsersPage({
        skip: (page - 1) * 10,
        limit: 10,
        keyword: userRequest.keyword,
        // followedBy: "test123",
      })
    );
  }, [page, userRequest]);

  const columns = [
    {
      title: "使用者名稱",
      dataIndex: "name",
      render: (data: any, row: { node: User }) => (
        <div className="user-name">
          <div className="user-avatar">
            <img src={row.node.avatarUrl} />
          </div>
          <span>{row.node.name}</span>
        </div>
      ),
    },
    {
      title: "ID",
      dataIndex: "id",
      render: (data: any, row: { node: User }) => <div>{row.node.id}</div>,
    },
    {
      title: "電子郵件",
      dataIndex: "email",
      render: (data: any, row: { node: User }) => <div>{row.node.email}</div>,
    },
    {
      title: "文章",
      dataIndex: "topicCount",
      render: (data: any, row: { node: User }) => (
        <div className="table-text-right">{row.node.topicCount}</div>
      ),
    },
    {
      title: "操作",
      dataIndex: "id",
      render: (data: any, row: { node: User }) => (
        <Link
          to={
            location.pathname.indexOf("list") !== -1
              ? `${row.node.id}`
              : `member/list/${row.node.id}`
          }
          key={"operate" + row.node.id}
          className="operate"
          // onClick={() => {
          //   location.pathname.indexOf("list") !== -1
          //     ? navigate(`${id}`)
          //     : navigate(`member/list/${id}`);
          // }}
        >
          編輯
        </Link>
      ),
    },
  ];

  return (
    <div className="member-list-page">
      <div className="container">
        <div className="title">會員管理</div>
        <div className="search">
          <Input
            placeholder="搜尋帳號"
            onChange={(v) => {
              setKeyword(v.target.value);
            }}
          />
          <Button
            type="primary"
            className="submit"
            onClick={() => {
              setUserRequest({ keyword });
              setPage(0);
            }}
          >
            確認
          </Button>
        </div>

        <ListComponent
          columns={columns}
          dataSource={commonDataState.users?.edges}
          total_num={commonDataState.users?.pageInfo.totalCount}
          page={page}
          output={(v: number) => {
            setPage(v);
          }}
        />
      </div>
    </div>
  );
};

export default MemberList;
