import { useAppDispatch } from "@frontend/app/hooks";
import {
  clearTopic,
  CommonDataState,
  deleteComment,
  deleteTopic,
  getCategories,
  getComments,
  getTopic,
  setGoBack,
  updateTopic,
} from "@frontend/common/redux/commonSlice";
import { Button, Form, Input, Menu, Modal, Select } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./TopicEdit.scss";
import Icon from "@ant-design/icons";
import { ReactComponent as Delete } from "@frontend/assets/shared/delete.svg";
import PaginationComponent from "@frontend/common/components/PaginationComponent/PaginationComponent";
import moment from "moment";
import Buttons from "@frontend/common/components/Buttons/Buttons";

const TopicEdit = ({ props }: any) => {
  const dispatch = useAppDispatch();
  const commonDataState = useSelector(CommonDataState);
  const { id } = useParams();
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [commentID, setCommentID] = useState("");
  const [showDeleteCommentModal, setShowDeleteCommentModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    id && dispatch(getTopic({ id }));
  }, [id]);

  useEffect(() => {
    if (!commonDataState.topic) return;

    dispatch(
      getComments({
        skip: (page - 1) * 5,
        limit: 5,
        topicId: commonDataState.topic.id,
      })
    );
  }, [commonDataState.topic, page]);

  useEffect(() => {
    dispatch(setGoBack("/topic/list"));
    dispatch(getCategories({ skip: 0, limit: 100 }));

    return () => {
      dispatch(clearTopic());
      dispatch(setGoBack(""));
    };
  }, [dispatch]);

  return (
    commonDataState.topic && (
      <div className="topic-edit-page">
        <div className="topic-edit-menu">
          <div className="title">話題管理</div>

          <Button
            type="primary"
            danger
            className="delete-topic"
            onClick={() => {
              setShowDeleteCommentModal(true);
            }}
          >
            刪除文章
          </Button>
        </div>

        <div className="content">
          <div className="topic-edit-detail">
            <Form
              className="form-page"
              form={form}
              layout="vertical"
              onFinish={() => {
                form.validateFields().then((v) => {});
              }}
            >
              <Form.Item
                label="話題名稱"
                name="title"
                rules={[{ required: true, message: "不可空白" }]}
                initialValue={commonDataState.topic.title}
              >
                <Input type="text" />
              </Form.Item>

              <Form.Item
                label="編號"
                name="id"
                rules={[
                  {
                    required: true,
                    message: "不可空白",
                  },
                ]}
                initialValue={commonDataState.topic.id}
              >
                <Input type="text" />
              </Form.Item>

              <Form.Item
                label="編號"
                name="categoryId"
                initialValue={commonDataState.topic.category.id}
              >
                <Select>
                  {commonDataState.categories?.edges.map((v) => (
                    <Select.Option key={v.node.id} value={v.node.id}>
                      {v.node.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>

            <Button
              type="primary"
              className="submit"
              onClick={() => {
                form.validateFields().then((v) => {
                  updateTopic({
                    ...v,
                  })
                    .then((x) => {})
                    .catch((e) => {
                      console.log(e);
                    });
                });
              }}
            >
              確認
            </Button>
          </div>

          <Menu
            className="topic-menu"
            mode="inline"
            defaultOpenKeys={["sub1", "sub2"]}
          >
            <SubMenu
              key="sub1"
              title={<div className="sub-menu-title">話題選項</div>}
            >
              {commonDataState.topic.options.map((v) => (
                <Menu.Item className="topic-option-item" key={v.id}>
                  {v.name}
                </Menu.Item>
              ))}
            </SubMenu>
            <SubMenu
              key="sub2"
              title={<div className="sub-menu-title">留言管理</div>}
            >
              {commonDataState.topic.comments.edges.map((v, index) => (
                <Menu.Item className="topic-comment-item" key={v.node.id}>
                  <div className="topic-comment-item-title">
                    <div className="topic-comment-item-detail">
                      <span className="comment-time">
                        {moment(v.node.createdAt).format("HH:mm")}
                      </span>
                      <span className="comment-user">{v.node.author.name}</span>
                      <span className="comment-floor">
                        B{(page - 1) * 5 + index + 1}
                      </span>
                    </div>

                    <Icon
                      className="delete-icon"
                      component={Delete}
                      onClick={() => {
                        setCommentID(v.node.id);
                        setShowDeleteCommentModal(true);
                      }}
                    />
                  </div>

                  <span className="topic-comment-item-content">
                    {v.node.content}
                  </span>
                </Menu.Item>
              ))}

              <PaginationComponent
                props={{
                  total_num: commonDataState.topic.comments.pageInfo.totalCount,
                  page,
                  average_num: 5,
                }}
                output={(v: number) => {
                  setPage(v);
                }}
              />
            </SubMenu>
          </Menu>
        </div>

        <Modal
          title="通知"
          className="delete-topic-modal"
          visible={showDeleteCommentModal}
          onCancel={() => {
            setShowDeleteCommentModal(false);
            setCommentID("");
          }}
          centered
          destroyOnClose
        >
          <span className="delete-topic-content">確認要刪除?</span>
          <Buttons
            onOk={() => {
              if (commentID) {
                deleteComment({ commentID })
                  .then((v) => {
                    dispatch(
                      getComments({
                        skip: (page - 1) * 5,
                        limit: 5,
                        topicId: commonDataState.topic?.id || "",
                      })
                    );
                    setShowDeleteCommentModal(false);
                    setCommentID("");
                  })
                  .catch((e) => {});
              } else {
                deleteTopic({ topicID: commonDataState.topic?.id || "" })
                  .then((v) => {
                    navigate("/topic/list");
                  })
                  .catch((e) => {});
              }
            }}
            onCancel={() => {
              setShowDeleteCommentModal(false);
              setCommentID("");
            }}
          />
        </Modal>
      </div>
    )
  );
};

export default TopicEdit;
