import React, { useEffect, useState } from "react";
import "./NoticeList.scss";
import ListComponent from "@frontend/common/components/ListComponent/ListComponent";
import { Button, DatePicker, Input, notification } from "antd";
import Locale from "antd/es/date-picker/locale/zh_TW";
import "moment/locale/zh-tw";
import { useAppDispatch, useAppSelector } from "@frontend/app/hooks";
import {
  cancelNotificationTask,
  CommonDataState,
  getNotificationTasks,
  sendNotification,
} from "@frontend/common/redux/commonSlice";
import moment from "moment";
import { NotificationTask } from "@frontend/common/models/response.model";
import { NotifiError } from "@frontend/common/models/common.model";

const NoticeList = ({ props }: any) => {
  const dispatch = useAppDispatch();
  const commonDataState = useAppSelector(CommonDataState);
  const [page, setPage] = useState(1); //0 = 重新撈取

  const [sendNotificationRequest, setSendNotificationRequest] = useState({
    sendTime: "",
    title: "",
    content: "",
  });
  const [openDate, setOpenDate] = useState(false);

  const columns = [
    {
      title: "標題",
      dataIndex: "title",
      render: (data: any, row: { node: NotificationTask }) => (
        <div>{row.node.title}</div>
      ),
    },
    {
      title: "內容",
      dataIndex: "content",
      render: (data: any, row: { node: NotificationTask }) => (
        <div>{row.node.content}</div>
      ),
    },
    {
      title: "時間",
      dataIndex: "time",
      render: (data: any, row: { node: NotificationTask }) => (
        <div>{moment(row.node.time).format("YYYY-MM-DD hh:mm")}</div>
      ),
    },
    {
      title: "操作",
      dataIndex: "time",
      render: (data: string, row: { node: NotificationTask }) =>
        row.node.cancelAt ? (
          <div>已取消</div>
        ) : (
          <div className="operate">
            <span
              onClick={() => {
                cancelNotificationTask({ taskID: row.node.id })
                  .then((v) => {
                    dispatch(
                      getNotificationTasks({
                        skip: (page - 1) * 10,
                        limit: 10,
                        isSent: false,
                      })
                    );
                  })
                  .catch((e) => {});
              }}
            >
              取消發送
            </span>
          </div>
        ),
    },
  ];

  const disabledDate = (current: any) => {
    return current && current < moment().endOf("day");
  };

  useEffect(() => {
    dispatch(
      getNotificationTasks({
        skip: (page - 1) * 10,
        limit: 10,
        isSent: false,
      })
    );
  }, [page]);

  return (
    <div className="notice-list-page">
      <div className="container">
        <div className="title">推播管理</div>

        <div className="description">
          <span>您可以在本頁進行平台的推播與發送管理</span>
        </div>

        <div className="notice-send">
          <span className="title">手動發送推播</span>
          <div style={{ display: "flex" }}>
            <div className="notice-send-time">
              <div className="label">發送時間</div>
              <DatePicker
                disabledDate={disabledDate}
                open={openDate}
                showToday={false}
                locale={Locale}
                onPanelChange={(v) => {
                  console.log(v);
                }}
                renderExtraFooter={() => (
                  <div className="notice-send-time-buttons">
                    <Button
                      className="cancel"
                      onClick={() => {
                        setOpenDate(false);
                      }}
                    >
                      取消
                    </Button>
                    <Button
                      type="primary"
                      className="submit"
                      onClick={() => {
                        setOpenDate(false);
                      }}
                    >
                      確認
                    </Button>
                  </div>
                )}
                onOpenChange={(v) => {
                  v && setOpenDate(true);
                }}
                onChange={(v) => {
                  setSendNotificationRequest({
                    ...sendNotificationRequest,
                    sendTime: moment(v).format(),
                  });
                }}
              />
            </div>

            <div className="notice-send-title">
              <div className="label">標題</div>
              <Input
                value={sendNotificationRequest.title}
                onChange={(v) => {
                  setSendNotificationRequest({
                    ...sendNotificationRequest,
                    title: v.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="notice-send-content">
            <div className="label">內容</div>
            <Input.TextArea
              value={sendNotificationRequest.content}
              onChange={(v) => {
                setSendNotificationRequest({
                  ...sendNotificationRequest,
                  content: v.target.value,
                });
              }}
            />
          </div>

          <Button
            type="primary"
            className="submit"
            onClick={() => {
              if (
                sendNotificationRequest.title &&
                sendNotificationRequest.content &&
                sendNotificationRequest.sendTime
              ) {
                sendNotification(sendNotificationRequest)
                  .then((v) => {
                    dispatch(
                      getNotificationTasks({
                        skip: (page - 1) * 10,
                        limit: 10,
                        isSent: false,
                      })
                    );
                    setSendNotificationRequest({
                      ...sendNotificationRequest,
                      title: "",
                      content: "",
                    });
                  })
                  .catch((e) => {});
              } else {
                notification.error(
                  NotifiError(
                    `請輸入${
                      sendNotificationRequest.sendTime ? "" : " 發送時間"
                    }${sendNotificationRequest.title ? "" : " 標題"}${
                      sendNotificationRequest.content ? "" : " 內容"
                    }`,
                    404
                  )
                );
              }
            }}
          >
            發送
          </Button>
        </div>

        <div className="title list-title">發送紀錄</div>
        <ListComponent
          columns={columns}
          dataSource={commonDataState.notificationTasks?.edges}
          total_num={commonDataState.notificationTasks?.pageInfo.totalCount}
          page={page}
          output={(v: number) => {
            setPage(v);
          }}
        />
      </div>
    </div>
  );
};

export default NoticeList;
