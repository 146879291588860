import { Button } from "antd";
import React from "react";
import "./Buttons.scss";

const Buttons = ({
  onOk = () => {},
  onCancel = () => {},
}: {
  onOk: Function;
  onCancel: Function;
}) => {
  return (
    <div className="buttons-page">
      <Button
        type="primary"
        className="submit"
        onClick={() => {
          onOk();
        }}
      >
        確認
      </Button>
      <Button
        className="cancel"
        onClick={() => {
          onCancel();
        }}
      >
        取消
      </Button>
    </div>
  );
};

export default Buttons;
