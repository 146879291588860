import React, { useEffect } from "react";
import "./App.scss";
import Login from "@frontend/pages/Login/Login";
import { useNavigate, useRoutes } from "react-router-dom";
import Header from "@frontend/components/Header/Header";
import SideMenu from "@frontend/components/SideMenu/SideMenu";
import MemberList from "@frontend/pages/Member/MemberList/MemberList";
import ClassList from "@frontend/pages/class/ClassList/ClassList";
import PermissionList from "@frontend/pages/permission/PermissionList/PermissionList";
import TopicList from "@frontend/pages/topic/TopicList/TopicList";
import NoticeList from "@frontend/pages/notice/NoticeList/NoticeList";
import { useAppDispatch, useAppSelector } from "@frontend/app/hooks";
import { CommonDataState, loadToken } from "@frontend/common/redux/commonSlice";
import MemberEdit from "@frontend/pages/Member/MemberEdit/MemberEdit";
import TopicEdit from "@frontend/pages/topic/TopicEdit/TopicEdit";

function App() {
  const dispatch = useAppDispatch();
  const commonDataState = useAppSelector(CommonDataState);

  let element = useRoutes([
    {
      path: "/",
      element: <MemberList />,
    },
    {
      path: "member",
      children: [
        {
          path: "list",
          element: <MemberList />,
        },
        {
          path: "list/:id",
          element: <MemberEdit />,
        },
      ],
    },
    {
      path: "class",
      children: [
        {
          path: "list",
          element: <ClassList />,
        },
      ],
    },
    {
      path: "permission",
      children: [
        {
          path: "list",
          element: <PermissionList />,
        },
      ],
    },
    {
      path: "topic",
      children: [
        {
          path: "list",
          element: <TopicList />,
        },
        {
          path: "list/:id",
          element: <TopicEdit />,
        },
      ],
    },
    {
      path: "notice",
      children: [
        {
          path: "list",
          element: <NoticeList />,
        },
      ],
    },
  ]);

  useEffect(() => {
    dispatch(loadToken());
  }, [dispatch]);

  return !commonDataState.token ? (
    <Login />
  ) : (
    <div className="app-page">
      <SideMenu />

      <div className="app-content">
        <Header />

        <div className="app-routes">{element}</div>
      </div>
    </div>
  );
}

export default App;
