import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import commonSlice from "@frontend/common/redux/commonSlice";

const combinedReducer = combineReducers({
  common: commonSlice,
});

export const rootReducer = (state: any, action: any) => {
  const token = localStorage.getItem("token");

  if (!token) {
    state = {};
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
